<template>
	<div class="container">
		<div class="btnBox">
			<!-- 新增修改删除，已经接入，暂时不开放 -->

			<!-- <el-button @click="openShowDialog('save')" icon="el-icon-plus" type="primary">新增</el-button> -->
			<!-- <el-dropdown @command="allSelect">
				<el-button type="primary">
					批量操作<i class="el-icon-arrow-down el-icon--right"></i>
				</el-button>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="0">批量发布</el-dropdown-item>
					<el-dropdown-item command="1">批量隐藏</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown> -->
		</div>
		<!-- 表格列表 -->
		<div class="mt50">
			<el-table ref="multipleTable" width="100%" @selection-change="handleSelectionChange" row-key="id" lazy
				v-loading="tableLoading" :load="load" :data="tableData" border
				:tree-props="{children: 'children', hasChildren: 'hasChildren'}"
				:header-cell-style="{background:'#F9FAFC',color:'#191919','font-weight':'100',border:'none','border-bottom':'1px solid #E2E7EB','text-align':'center' }">
				<!-- <el-table-column type="selection" width="55" align="center"></el-table-column> -->
				<el-table-column prop="id" label="ID" align="center" width="140"></el-table-column>
				<el-table-column prop="name" label="城市名称" align="center"></el-table-column>
				<!-- <el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<el-button type="text" @click="openShowDialog('view',scope.row.id,scope.row)" class="c-info-imp"
							align="center">查看</el-button>
						<el-button type="text" @click="openShowDialog('update',scope.row.id,scope.row)" class="c-green"
							align="center">修改</el-button>
						<el-button type="text" @click="delRow(scope.row.id)" class="c-danger-imp" align="center">删除</el-button>
					</template>
				</el-table-column> -->
			</el-table>
		</div>

		<!-- 新增/修改 -->
		<el-dialog :title="dialogTitle" top="15vh" :visible.sync="dialogVisible"
			:close-on-click-modal="dialogTitle=='查看'" width="40%" :before-close="closeDialog">
			<el-form ref="form" :rules="rules" :model="formParam" label-width="180px" :disabled="dialogTitle=='查看'">
				<el-form-item label="上级分类" prop="pid">
					<el-cascader :disabled="dialogTitle!='新增'" v-model="parentIdArr" clearable placeholder="请选择分类"
						:options="tableDataTree" :show-all-levels="true" @change="classifyChange"
						:props="{ checkStrictly: true ,label:'name',value:'id' }"></el-cascader>
				</el-form-item>
				<el-form-item label="城市名称" prop="name">
					<el-input v-model="formParam.name" placeholder="请输入内容"></el-input>
				</el-form-item>
				<el-form-item label="排序号" prop="sort">
					<el-input-number v-model="formParam.sort"></el-input-number>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer" v-show="dialogTitle!='查看'">
				<el-button @click="closeDialog">取 消</el-button>
				<el-button type="primary" :loading="btnLoading" @click="submit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import {
		treeDataTranslate
	} from '@/utils'
	export default {
		data() {
			return {
				newArea: [],
				tableData: [],
				tableDataTree: [],
				cityDisabled: false,
				cityLevelData: [], //城市等级
				rules: {
					name: [{
						required: true,
						message: '请输入分类名称',
						trigger: 'change'
					}],
					sort: [{
						required: true,
						message: '请输入排序',
						trigger: 'change'
					}],
					cityLevel: [{
						required: true,
						message: '请选择城市等级',
						trigger: 'change'
					}],
				},
				activeMenu: "",
				formParam: {
					priority: 0,
					name: '',
					treeLevel:'',
					pid: ''
				},
				dialogVisible: false, //弹窗
				dialogTitle: "",
				btnLoading: false,
				parentIdArr: [],
				multipleSelection: [],
				tableLoading: false
			}
		},
		created() {
			this.loadData();
			// this.getCityLeave()
		},
		methods: {

			//批量操作
			allSelect(e) {
				let length = this.multipleSelection.length;
				if (length && length > 0) {
					if (e == 0) {
						this.recommendCourse()
					} else if (e == 1) {
						this.cancelRecommend()
					}
				} else {
					this.$message.warning('请选择要操作的数据');
				}
			},
			load(tree, treeNode, resolve) {
				let resolveArr = []
				if (tree.treeLevel == 0) { //1级
					this.newArea.forEach(item => {
						if (item.id == tree.id) {
							item.children.forEach(keys => {
								if (keys.children.length > 0) {
									keys.data.data.hasChildren = true
								}
								if (keys.data.data) {
									resolveArr.push(keys.data.data)
								}
							})
						}
					})
				} else if (tree.treeLevel == 1) { //2级
					this.newArea.forEach(item => {
						if (item.id == tree.parentId) {
							item.children.forEach(keys => {
								if (keys.id == tree.id) {
									keys.children.forEach(j => {
										if (j.children.length > 0) {
											j.data.data.hasChildren = true
										}
										if (j.data.data) {
											resolveArr.push(j.data.data)
										}
									})
								}

							})
						}
					})
				} else { //3级

				}


				setTimeout(() => {
					resolve(resolveArr)
				}, 200)


			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			async loadData() {
				this.tableLoading = true;
				this.tableData = [];
				let res = await this.$get("/platform-admin/region/tree");
				if (res && res.code == 0) {
					this.newArea = treeDataTranslate(res.data, 'id', 'pid')
					this.tableData = this.newArea
					this.tableDataTree = this.newArea
					this.tableLoading = false;
				}
			},
			// 删除
			delRow(id) {
				this.$confirm('确定删除此分类?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					let res = await this.$post(`area/delete`, {
						id: id
					}, true, "form");
					if (res.code === 0) {
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
						this.loadData();
					}
				}).catch(() => {});
			},
			// 分类下拉框change
			classifyChange(e) {
				if (e.length == 1) {
					this.formParam.treeLevel = 1
					this.formParam.pid = e[0]
					this.cityDisabled = true
				} else {
					this.cityDisabled = false
					this.formParam.treeLevel = 2
					this.formParam.pid = e[1]
				}

			},
			// 打开弹窗
			openShowDialog(val, id, row) {
				if (val == "save") {
					this.dialogTitle = "新增";
					this.formParam.pid=0
				} else if (val == "view") {
					this.dialogTitle = "查看"
					this.loadInfo(id, row);
				} else if (val == "update") {
					this.dialogTitle = "修改"
					this.loadInfo(id, row);
					this.formParam.id = id;
				}
				this.dialogVisible = true
			},
			// 关闭 弹窗
			closeDialog() {
				this.dialogVisible = false;
				this.$refs['form'].resetFields(); //清楚验证
				this.cityDisabled = false;
				this.parentIdArr = []
				this.formParam = {
					priority: 0,
					name: '',
					treeLevel: 0,
					parentId: null
				}
			},

			/**
			 * 查询详情
			 * */
			loadInfo(id, row) {
				let that = this;
				let currId = null;
				if (row.treeLevel > 1) {
					currId = row.parentId;
				} else {
					currId = id;
				}
				that.$get(`/platform-admin/region/${id}`).then(res => {
					if (res.code === 0) {
						// that.formParam = JSON.parse(JSON.stringify(row));
						that.formParam=res.data
						if (res.data.id) {
							if (row.treeLevel > 1) {
								that.parentIdArr = [res.data.pid.toString(), res.data.id.toString()]
							} else {
								that.parentIdArr = [res.data.pid.toString()]
							}
						}

					}
				});

			},

			//提交
			submit() {
				this.$refs['form'].validate(async (valid) => {
					if (valid) {
						this.btnLoading = true;
						let msg = "新增成功！";
						if (this.formParam.id) {
							msg = "修改成功！";
						}
						let res
						if(this.formParam.id){
							res = await this.$put('/platform-admin/region', this.formParam);
						}else{
							res = await this.$post('/platform-admin/region', this.formParam);
						}
						if (res.code === 0) {
							this.$message.success(msg);
							this.loadData();
						}
						this.closeDialog();
						this.btnLoading = false;
					}
				})
			}
		},

	}
</script>

<style scoped>
	.container {
		padding: 20px;
		box-sizing: border-box;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
		border: 1px dashed #d9d9d9;
	}

	.avatar-uploader-icon:hover {
		border-color: #409EFF;
	}

	.avatar {
		width: 178px;
		/* height: 100px; */
		display: block;
	}

	.table-img {
		max-height: 100px;
		max-width: 100px;
	}
	.btnBox{
		margin-bottom: 15px;
	}
</style>
